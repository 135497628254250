/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@import "./theme.scss";

* {
  box-sizing: border-box
}

[role="button"],
.pointer {
  cursor: pointer;
}

.right,
td.right,
th.right,
th.mat-header-cell.right,
td.action,
th.action,
td.actions,
th.actions {
  text-align: right;
}

.center {
  text-align: center;
}

.no-wrap {
  white-space: nowrap;
}

td.action,
th.action {
  width: 75px;
  max-width: 75px;
}


mat-card-actions.mat-card-actions.flex-row,
.flex-row,
.flex-row-responsive {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-rows {
  display: flex;
  flex-direction: row;
}

@media (max-width: 900px) {
  .flex-row.responsive {
    flex-direction: column;
  }
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex {
  flex: 1 1 auto;
}

button.big-square-button {
  height: 150px;
  width: 150px;
  max-height: 150px;
  max-width: 150px;
  white-space: normal;
  overflow: hidden;
  line-height: 1.25;
  font-size: 18px;
  margin: 5px;
}

.description-item {
  padding: 3px;
  display: flex;
  font-weight: 500;

  > div:first-child {
    width: 200px;
    max-width: 50%;
    text-align: right;
    margin-right: 5px;
    font-weight: 300;
  }

  > div:first-child::after {
    content: ":";
  }
}

.page-container {
  width: 95%;
  max-width: 95%;
  min-width: 95%;
  margin: 0 auto;
  display: block;
  box-sizing: border-box;
  margin-bottom: 15px;
}

table.table-alpha{
  width: 95%;
  max-width: 95%;
  min-width: 95%;
  margin: 0 auto;
  th.mat-header-cell,
  td.mat-cell,
  th.mat-cell {
    padding: 5px 10px;
  }
  &:not(.not-stripped) {
    tr:nth-child(odd)>td {
      background-color: #e6e6e6;
    }
    tr:hover >td {
      background-color: #cfcfcf;
    }
  }
}

tr.odd-stripe {
  background-color: #e6e6e6;
}

tr.even-stripe {
  background-color: #cfcfcf;
}

mat-card {
  table.table-alpha {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
}

th {
  break-inside:avoid
}


mat-card table.table-alpha,
mat-expansion-panel table.table-alpha {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}

@media (max-width: 600px) {
  .hide-sm {
    display: none;
  }
}

textarea {
  min-height: 75px;
}

button[mat-menu-item] {
  display: flex;
  align-items: center;
}
.mat-button-wrapper {
  mat-icon + span {
    margin-inline-start: 5px;;
  }
}


formly-group.flex-row {
  formly-field + formly-field {
    margin-left: 5px;
  }
  formly-field {
    flex: 1
  }
  &.fit-content {
    .mat-form-field-infix {
      width: unset;
    }
  }
}

formly-group.form-grid {
  display: grid; 
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  column-gap: 1em;
  max-width: 100%;
}


.toast-success {
  background-color: #006e00;
  border: 1px solid white;
}

.toast-error {
  background-color: rgb(139, 0, 0);
  border: 1px solid white;
}

.inline-error {
  background-color: rgb(139, 0, 0);
  border: 1px solid black;
  padding: 3px 5px;
  color: white;
  font-weight: bold; 
  border-radius: 5px;
}

p.time-description-block {
  text-align: center;
  margin-top: -20px;
  &.error {
    color: #f44336;
    font-weight: bold;
  }
  &.warning {
    border: 1px solid orange;
    span::after,
    span::before {
      content: ' ⚠ ';
      font-weight: bold;
      color: orange;
    }
  }
}

.linky {
  color: steelblue;
}
.linky:hover {
  text-decoration: underline;
}

#preview.mat-dialog-container {
  padding: 0;
  overflow: hidden;
  .close {
    text-align: center;
    position: absolute;
    top: 24px;
    background-color: white;
    color: black;
    width: 110px;
    cursor: pointer;
  }
}

table {
  max-width: 100%;
}

/* At the top level of your code */
@media print {
  button {
    display: none;
  }
}